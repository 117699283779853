export const useLoadUserData = () => {
  const user = useSupabaseUser()
  const client = useSupabaseClient()

  const toast = useToast()

  const { userData } = storeToRefs(useUserDataStore())

  const { refresh: refreshUserData, execute: excuteUserData } = useAsyncData('storeUserData', async () => {
    if (!user.value?.id) {
      return {}
    }

    const defaultUserQuery = '*'
    const avatarListQuery = 'avatarList!avatarGroup(*)'
    const loationListQuery = 'locationList!locationGroup(*)'
    const snsListQuery = 'snsList!snsGroup(*)'
    const userCouponQuery = 'userCouponList!userCouponGroup(*)'
    const motoGenreQuery = 'motoGenreList!motoGenreGroup(*)'
    const repairSkillQuery = 'repairSkillList!repairSkillGroup(*)'
    const pickupItemQuery = 'pickupItemList!pickupItemGroup(*)'
    const businessHourQuery = 'businessHourList!businessHourGroup(*)'
    const shopImageQuery = 'shopImageList!shopImageGroup(*)'
    const shopCouponQuery = 'shopCouponList!shopCouponGroup(*)'
    const blindListQuery = 'blindList!blindGroup(id, activated, deleted)'
    const userDataQuery = defaultUserQuery.concat(', ', avatarListQuery, ', ', loationListQuery, ', ', snsListQuery, ', ', userCouponQuery, ', ', motoGenreQuery, ', ', repairSkillQuery, ', ', pickupItemQuery, ', ', businessHourQuery, ', ', shopImageQuery, ', ', shopCouponQuery, ', ', blindListQuery)

    const { data, error } = await client
      .from('profiles')
      .select(userDataQuery)
      .eq('id', user.value?.id)
      .order('index', { referencedTable: 'avatarList', ascending: true })
      .order('index', { referencedTable: 'locationList', ascending: true })
      .order('index', { referencedTable: 'snsList', ascending: true })
      .order('created_at', { referencedTable: 'userCouponList', ascending: true })
      .order('index', { referencedTable: 'motoGenreList', ascending: true })
      .order('index', { referencedTable: 'repairSkillList', ascending: true })
      .order('index', { referencedTable: 'businessHourList', ascending: true })
      .order('index', { referencedTable: 'shopImageList', ascending: true })
      .order('index', { referencedTable: 'shopCouponList', ascending: true })
      .eq('snsList.activated', true)
      .eq('blindList.activated', true)
      .eq('blindList.deleted', false)
      .eq('motoGenreList.deleted', false)
      .single()

    if (error) {
      toast.add({ title: error.message, description: 'at fetch userData', color: 'rose', timeout: 2000 })
    }

    userData.value = data as SerializeObject

    return data
  }, {
    immediate: true,
  })

  return {
    excuteUserData,
    refreshUserData,
  }
}
